import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const inputPlanState = {
  shopPrefecture: null,
  shopId: null,
  shopName: null,
  shopType: null,
  admissionYear: null,
  admissionMonth: null,
  admissionDay: null,
  paymentMethod: null,
  plan: null,
  options: null,
  referral: null,
  autoOptions: null,
};

const inputCorpPlanState = {
  shopPrefecture: null,
  shopId: null,
  shopName: null,
  shopType: null,
  numberOfUsers: null,
  admissionYear: null,
  admissionMonth: null,
  admissionDay: null,
  paymentMethod: null,
  plan: null,
  options: null,
  autoOptions: null,
};

const inputProfileState = {
  lastName: null,
  firstName: null,
  lastNameKana: null,
  firstNameKana: null,
  birthYear: null,
  birthMonth: null,
  birthDay: null,
  gender: null,
  phoneNumber: null,
  email: null,
  emailConfirm: null,
  password: null,
  zipcode: null,
  prefecture: null,
  city: null,
  street: null,
  idVerificationDocType: null,
  idDoc1: null,
  idDoc2: null,
  agree: null,
  triggerToJoin: null,
  purposeMembership: null,
  reasonsChoosingWorldPlus: null,
};

const inputCorpProfileState = {
  companyName: null,
  companyNameKana: null,
  companyPhoneNumber: null,
  companyZipcode: null,
  companyPrefecture: null,
  companyCity: null,
  companyStreet: null,
  lastName: null,
  firstName: null,
  lastNameKana: null,
  firstNameKana: null,
  birthYear: null,
  birthMonth: null,
  birthDay: null,
  gender: null,
  phoneNumber: null,
  email: null,
  emailConfirm: null,
  password: null,
  zipcode: null,
  prefecture: null,
  city: null,
  street: null,
  agree: null,
};

const inputPaymentState = {
  cardNo: null,
  cardExpirationYear: null,
  cardExpirationMonth: null,
  cvv: null,
  smbcBillKana: null,
};

export const inputState = atom({
  key: "input",
  default: {
    errorMsg: null,
    ...inputPlanState,
    ...inputProfileState,
    ...inputPaymentState,
  },
  effects_UNSTABLE: [persistAtom],
});

export const inputCorpState = atom({
  key: "corpInput",
  default: {
    errorMsg: null,
    ...inputCorpPlanState,
    ...inputCorpProfileState,
    ...inputPaymentState,
  },
  effects_UNSTABLE: [persistAtom],
});
