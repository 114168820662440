const LoadingComponent = ({payment}) => {
  return (
    <div className="loading js-loading">
      <div className="loading__mask"></div>
      <div className="loading__progress">
        <div className="loading__icon"></div>
        {payment && (
          <p class="loading__message">
            ただいま決済を確認しています。<br/>
            お待ちいただいている間に、画面を閉じたりリロードしないでください。<br/>
            30秒〜1分ほどお時間かかります。
          </p>
        )}
      </div>
    </div>
  );
};

export default LoadingComponent;