import useSWR from "swr";
import { FETCHER, API_ENDPOINT } from "./Constants";

export function useCsrf() {
  const { data, error, isLoading } = useSWR(API_ENDPOINT.CSRF, FETCHER);
  return {
    token: data,
    isCsrfLoading: isLoading,
    isCsrfError: error,
  };
}

export function useAllShop() {
  const { data, error, isLoading } = useSWR(API_ENDPOINT.ALL_SHOP, FETCHER);
  return {
    shops: data,
    isShopsLoading: isLoading,
    isShopsError: error,
  };
}

export function usePlanOptions(accountId) {
  const urlParams = new URLSearchParams(window.location.search);
  const specialplan = urlParams.get("specialplan");
  let specialplanQuery = '';
  if (specialplan) {
    specialplanQuery = `&specialplan=${specialplan}`;
  }
  const { data, error, isLoading } = useSWR(
    !!accountId ? API_ENDPOINT.PLAN_OPTIONS + `?accountId=${accountId}${specialplanQuery}` : null,
    FETCHER
  );

  return {
    planOptions: data,
    isPlansLoading: isLoading,
    isPlansError: error,
  };
}

export function useCorpPlanOptions(accountId) {
  const { data, error } = useSWR(
    !!accountId ? API_ENDPOINT.CORP_PLAN_OPTIONS + `?accountId=${accountId}` : null,
    FETCHER
  );
  return {
    planOptions: data,
    isPlansLoading: false,
    isPlansError: error,
  };
}

export function usePaymentInfo(
  planId,
  shopId,
  paymentMethod,
  options,
  admissionYear,
  admissionMonth,
  admissionDay
) {
  const { data, error, isLoading } = useSWR(
    API_ENDPOINT.PAYMENT_INFO +
      `?planId=${planId}&shopId=${shopId}&paymentMethod=${paymentMethod}&options=${options}&admissionYear=${admissionYear}&admissionMonth=${admissionMonth}&admissionDay=${admissionDay}`,
    FETCHER
  );

  return {
    paymentInfo: data,
    isPaymentInfoLoading: isLoading,
    isPaymentInfoError: error,
  };
}

export function useCorpPaymentInfo(
  planId,
  shopId,
  paymentMethod,
  options,
  admissionYear,
  admissionMonth,
  admissionDay,
  numberOfUsers
) {
  const { data, error, isLoading } = useSWR(
    API_ENDPOINT.PAYMENT_INFO +
      `?planId=${planId}&shopId=${shopId}&paymentMethod=${paymentMethod}&options=${options}&admissionYear=${admissionYear}&admissionMonth=${admissionMonth}&admissionDay=${admissionDay}&numberOfUsers=${numberOfUsers}`,
    FETCHER
  );

  return {
    paymentInfo: data,
    isPaymentInfoLoading: isLoading,
    isPaymentInfoError: error,
  };
}
