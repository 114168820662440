import React from "react";
import { useEffect } from "react";

const RegisterFailed = () => {

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  });

  return (
    <main className="main">
      {/* cover */}
      <div className="cover cover--contact cover--bg">
        <div className="cover__outer">
          <div className="cover__inner"></div>
        </div>
      </div>
      {/* /cover */}

      {/* location */}
      <div className="location">
        <p className="location__text"></p>
      </div>
      {/* /location */}

      {/* content */}
      <div className="content">
        <div className="content__outer">
          <div className="content__inner">
            <h1 className="content__title js-indicate js-indicate-btin">
              決済失敗
            </h1>

            {/* step */}
            <div className="step js-indicate js-indicate-btin">
              <div className="step__lists">
                <div className="step__list">
                  <span>
                    プラン<i className="pc">等の選択</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    会員<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list">
                  <span>
                    決済<i className="pc">情報の入力</i>
                  </span>
                </div>
                <div className="step__list step__list--current">
                  <span>
                    登録<i className="pc">完了</i>
                  </span>
                </div>
              </div>
            </div>
            {/* /step */}

            {/* message */}
            <div className="message js-indicate js-indicate-btin">
              <div className="message__box">
                <p className="message__title message__title--large">決済失敗</p>
                <p className="message__text message__text--light">
                  決済処理に失敗しました。
                  <br />
                  お手数ですが最初からやり直していただきますようお願いいたします。
                  <br />
                  ご不明な点は
                  <a href={process.env.REACT_APP_WEB_MAIN_URL + "/contact/"}>こちら</a>
                  にお問い合わせください。
                </p>
              </div>
            </div>
            {/* /message */}
          </div>
        </div>
      </div>
      {/* /content */}
    </main>
  );
};

export default RegisterFailed;
