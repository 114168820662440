function getStorageItem(storage, key) {
  const value = storage.getItem(key);
  if (value !== null) {
    try {
      const obj = JSON.parse(value);
      return obj;
    } catch (_) {
      return value;
    }
  }
  return "";
}

function removeStorageItem(storage, key) {
  storage.removeItem(key);
}

function setStorageItem(storage, key, value) {
  if (value !== null && typeof value === "object") {
    storage.setItem(key, JSON.stringify(value));
  } else {
    storage.setItem(key, value);
  }
}

function setStorageItems(storage, obj) {
  for (let key in obj) {
    setStorageItem(storage, key, obj[key]);
  }
}

export function getLocalStorageItem(key) {
  return getStorageItem(localStorage, key);
}

export function removeLocalStorageItem(key) {
  removeStorageItem(localStorage, key);
}

export function setLocalStorageItem(key, value) {
  setStorageItem(localStorage, key, value);
}

export function setLocalStorageItems(obj) {
  setStorageItems(localStorage, obj);
}

export function getSessionStorageItem(key) {
  return getStorageItem(sessionStorage, key);
}

export function removeSessionStorageItem(key) {
  removeStorageItem(sessionStorage, key);
}

export function setSessionStorageItem(key, value) {
  setStorageItem(sessionStorage, key, value);
}

export function setSessionStorageItems(obj) {
  setStorageItems(sessionStorage, obj);
}
