import moment from "moment";
const Fincode = window.Fincode;

/**
 * SMBC口座振替受付サービス（インターネット方式）連携function
 *
 * @export
 * @param {*} billNo 顧客番号（12桁の会員番号）
 * @param {*} billName 顧客名
 * @param {*} billNameKana 顧客名カナ（半角）
 * @param {*} memberId 会員ID
 */
export function smbcTransferFormSubmit(
  billNo,
  billName,
  billNameKana,
  memberId,
  recordType='individual'
) {
  // SMBC決済ステーション遷移用フォーム生成
  const form = document.createElement("form");
  form.action = process.env.REACT_APP_SMBC_ACTION_URL;
  form.method = "post";
  form.acceptCharset = "Shift_JIS";
  form.enctype = "application/x-www-form-urlencoded";

  // formdataイベントリスナーの設定
  form.addEventListener("formdata", (e) => {
    const formData = e.formData;
    formData.set("version", "130"); // [バージョン] 130：PC（口座振替受付）
    formData.set("bill_method", "01"); // [決済手段区分] 01：口座振替
    formData.set("kessai_id", "0101"); // [決済種類コード] 0101：口座振替
    formData.set("shop_cd", process.env.REACT_APP_SMBC_SHOP_CD); // [契約コード] shop_cd: 4359946
    formData.set("syuno_co_cd", process.env.REACT_APP_SMBC_SYUNO_CD); // [収納企業コード] syuno_co_cd: 57236
    formData.set("shop_pwd", process.env.REACT_APP_SMBC_SHOP_PWD); // [ショップパスワード] shop_pwd: wpgtest0422
    formData.set("shoporder_no", moment().format("YYYYMMDDHHmmss")); // [契約補助番号]
    formData.set("koushin_kbn", "1"); // [上書可否区分] 1:上書許可
    formData.set("bill_no", billNo); // [顧客番号]
    formData.set("bill_name", hankakuZenkaku(billName)); // [顧客名]
    formData.set("bill_kana", billNameKana); // [顧客カナ名]
    formData.set(
      "shop_link",
      window.location.origin +
        process.env.REACT_APP_SMBC_SHOP_LINK +
        "?memberId=" +
        memberId +
        `&recordType=${recordType}`
    ); // [遷移先URL] 申込完了の場合に申込手続結果画面から遷移する加盟店URL
    formData.set(
      "shop_res_link",
      window.location.origin + process.env.REACT_APP_SMBC_SHOP_RES_LINK
    ); // [結果通知URL] 申込完了及び申込エラー通知の通知先URL
    formData.set(
      "shop_error_link",
      window.location.origin +
        process.env.REACT_APP_SMBC_SHOP_ERROR_LINK +
        "?memberId=" +
        memberId +
        `&recordType=${recordType}`
    ); // [エラー時遷移先URL] 申込完了以外の場合に遷移する加盟店URL
    formData.set("redirect_kbn", "1"); // [リダイレクト区分] 1：リダイレクトする
    formData.set("redirect_sec", "10"); // [リダイレクト秒数]
    formData.set("shop_phon_hyoji_kbn", "1"); // [ショップ連絡先電話番号表示区分] 1:表示する
    formData.set("shop_mail_hyoji_kbn", "1"); // [ショップ連絡先メールアドレス表示区分] 1:表示する
  });

  // formをdocumentに埋め込み
  document.body.appendChild(form);
  // サブミット
  form.submit();
}

/**
 * GMO のトークン取得を行うための一連のフローをまとめたfunction
 *
 * @export
 * @param {*} cardNo トークン化するカード番号
 * @param {*} expire トークン化するカードの有効期限
 * @param {*} securityCode トークン化するカードのセキュリティコード
 * @param {*} callback トークン取得後にコールバックする、JavaScript function
 */
// export function gmoGetToken(cardNo, expire, securityCode, callback) {
//   window[callback.name] = callback;
//   Multipayment.init(process.env.REACT_APP_GMO_SHOP_ID);
//   Multipayment.getToken(
//     { cardno: cardNo, expire: expire, securitycode: securityCode },
//     callback
//   );
// }

/**
 * GMO のトークン取得を行うための一連のフローをまとめたfunction
 *
 * @export
 * @param {*} cardNo トークン化するカード番号
 * @param {*} expire トークン化するカードの有効期限
 * @param {*} securityCode トークン化するカードのセキュリティコード
 * @param {*} holderName トークン化するカードのカード名義
 * @param {*} callback トークン取得後にコールバックする、JavaScript function
 */
export function gmoFinCodeGetToken(cardNo, expire, securityCode, holderName, callback) {
  let fincode = Fincode(process.env.REACT_APP_FINCODE_PUBLIC_KEY)
  fincode.tokens(
    { card_no: cardNo, expire: expire, security_code: securityCode, holder_name: holderName },
    callback
  );
}

/**
 * 半角を全角に
 * @param {string} str 'ABC'
 * @returns {string} 'ＡＢＣ'
 */
function hankakuZenkaku(str) {
  return str.replace(/[!-~ ]/g, function (s) {
    if (s === " ") {
      return "　";
    }
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
  });
}
