import { useEffect } from "react";
import "./App.scss";
import { ROUTES } from "./Constants";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { RecoilRoot } from "recoil";

import InputPlan from "./components/input-plan";
import InputCorpPlan from "./components/corp-input-plan";
import InputProfile from "./components/input-profile";
import InputPayment from "./components/input-payment";
import RegisterComplete from "./components/register-complete";
import RegisterFailed from "./components/register-failed";
import ScrollToTop from "./utils/ScrollTop";
import TagManager from "react-gtm-module";
import LoadingComponent from "./components/loading";
import InputCorpProfile from "./components/corp-input-profile";
import InputCorpPayment from "./components/corp-input-payment";
import RegisterCorpComplete from "./components/corp-register-complete";
import RegisterCorpFailed from "./components/corp-register-failed";

const App = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CONTAINER_ID });
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <RecoilRoot>
        <Routes>
          <Route path="/" element={<Navigate to={ROUTES.plan} />} />
          <Route index path={ROUTES.plan} element={<InputPlan />} />
          <Route path={ROUTES.profile} element={<InputProfile />} />
          <Route path={ROUTES.payment} element={<InputPayment />} />
          <Route path={ROUTES.corpPlan} element={<InputCorpPlan />} />
          <Route path={ROUTES.corpProfile} element={<InputCorpProfile />} />
          <Route path={ROUTES.corpPayment} element={<InputCorpPayment />} />
          <Route
            path={ROUTES.corp_register_complete}
            element={<RegisterCorpComplete />}
          />
          <Route
            path={ROUTES.register_complete}
            element={<RegisterComplete />}
          />
          <Route path={ROUTES.register_failed} element={<RegisterFailed />} />
          <Route
            path={ROUTES.corp_register_failed}
            element={<RegisterCorpFailed />}
          />
          <Route
            path={ROUTES.loading}
            element={<LoadingComponent payment={true} />}
          />
          <Route path="*" element={<Navigate to={ROUTES.plan} />} />
        </Routes>
      </RecoilRoot>
    </BrowserRouter>
  );
};

export default App;
